import retro1 from "../../assets/images/postcardRetro1.jpg";
import retro2 from "../../assets/images/postcardRetro2.jpg";
import retro3 from "../../assets/images/postcardRetro3.jpg";
import retro4 from "../../assets/images/postcardRetro4.jpg";
import retro5 from "../../assets/images/postcardRetro5.jpg";
import retro6 from "../../assets/images/postcardRetro6.jpg";

const presetPostcardRetro = [
  {
    id: 1,
    src: retro1,
    thumb: retro1,
    useText: 4,

    text1:
      "Elit consectetur sit exercitation\nadipiscing sit et sed dolor nostrud\nvoluptate eiusmod ipsum lorem sit\nconsequat do fugiat laboris sed.\nAmet eiusmod nostrud adipiscing\naliqua enim veniam et aliquip\ncommodo consequat dolore\nesse sed adipiscing cillum\nsed elit et incididunt.",
    top1: 18,
    left1: 3,
    size1: 0.9,
    color1: "#444444",
    border1: 0,
    borderColor1: "#444444",
    alignText1: "left",

    text2: "Mario Rossi",
    top2: 51,
    left2: 52,
    size2: 1.5,
    color2: "#444444",
    border2: 0,
    borderColor2: "#444444",
    alignText2: "left",

    text3: "Via Corso Garibaldi 1",
    top3: 60,
    left3: 52,
    size3: 1.2,
    color3: "#444444",
    border3: 0,
    borderColor3: "#444444",
    alignText3: "left",

    text4: "Milano",
    top4: 68,
    left4: 52,
    size4: 1.2,
    color4: "#444444",
    border4: 0,
    borderColor4: "#444444",
    alignText4: "left",

    textOutside: false,
    imageSelected: null,
  },
  {
    id: 2,
    src: retro2,
    thumb: retro2,
    useText: 4,

    text1:
      "Elit consectetur sit exercitation\nadipiscing sit et sed dolor nostrud\nvoluptate eiusmod ipsum lorem sit\nconsequat do fugiat laboris sed.\nAmet eiusmod nostrud adipiscing\naliqua enim veniam et aliquip\ncommodo consequat dolore\nesse sed adipiscing cillum\nsed elit et incididunt.",
    top1: 18,
    left1: 3,
    size1: 0.9,
    color1: "#444444",
    border1: 0,
    borderColor1: "#444444",
    alignText1: "left",

    text2: "Mario Rossi",
    top2: 51,
    left2: 54,
    size2: 1.4,
    color2: "#444444",
    border2: 0,
    borderColor2: "#444444",
    alignText2: "left",

    text3: "Via Corso Garibaldi 1",
    top3: 59,
    left3: 54,
    size3: 1,
    color3: "#444444",
    border3: 0,
    borderColor3: "#444444",
    alignText3: "left",

    text4: "Milano",
    top4: 66,
    left4: 54,
    size4: 1,
    color4: "#444444",
    border4: 0,
    borderColor4: "#444444",
    alignText4: "left",

    textOutside: false,
    imageSelected: null,
  },
  {
    id: 3,
    src: retro3,
    thumb: retro3,
    useText: 4,

    text1:
      "Elit consectetur sit exercitation\nadipiscing sit et sed dolor nostrud\nvoluptate eiusmod ipsum lorem sit\nconsequat do fugiat laboris sed.\nAmet eiusmod nostrud adipiscing\naliqua enim veniam et aliquip\ncommodo consequat dolore\nesse sed adipiscing cillum\nsed elit et incididunt.",
    top1: 18,
    left1: 3,
    size1: 0.9,
    color1: "#444444",
    border1: 0,
    borderColor1: "#444444",
    alignText1: "left",

    text2: "Mario Rossi",
    top2: 55,
    left2: 52,
    size2: 1.3,
    color2: "#444444",
    border2: 0,
    borderColor2: "#444444",
    alignText2: "left",

    text3: "Via Corso Garibaldi 1",
    top3: 62,
    left3: 52,
    size3: 0.9,
    color3: "#444444",
    border3: 0,
    borderColor3: "#444444",
    alignText3: "left",

    text4: "Milano",
    top4: 68,
    left4: 52,
    size4: 0.9,
    color4: "#444444",
    border4: 0,
    borderColor4: "#444444",
    alignText4: "left",

    textOutside: false,
    imageSelected: null,
  },
  {
    id: 4,
    src: retro4,
    thumb: retro4,
    useText: 4,

    text1:
      "Elit consectetur sit exercitation\nadipiscing sit et sed dolor nostrud\nvoluptate eiusmod ipsum lorem sit\nconsequat do fugiat laboris sed.\nAmet eiusmod nostrud adipiscing\naliqua enim veniam et aliquip\ncommodo consequat dolore\nesse sed adipiscing cillum\nsed elit et incididunt.",
    top1: 18,
    left1: 3,
    size1: 0.9,
    color1: "#444444",
    border1: 0,
    borderColor1: "#444444",
    alignText1: "left",

    text2: "Mario Rossi",
    top2: 48,
    left2: 57,
    size2: 1.2,
    color2: "#444444",
    border2: 0,
    borderColor2: "#444444",
    alignText2: "left",

    text3: "Via Corso Garibaldi 1",
    top3: 56,
    left3: 57,
    size3: 0.9,
    color3: "#444444",
    border3: 0,
    borderColor3: "#444444",
    alignText3: "left",

    text4: "Milano",
    top4: 63,
    left4: 57,
    size4: 0.9,
    color4: "#444444",
    border4: 0,
    borderColor4: "#444444",
    alignText4: "left",

    textOutside: false,
    imageSelected: null,
  },
  {
    id: 5,
    src: retro5,
    thumb: retro5,
    useText: 4,

    text1:
      "Elit consectetur sit exercitation\nadipiscing sit et sed dolor nostrud\nvoluptate eiusmod ipsum lorem sit\nconsequat do fugiat laboris sed.\nAmet eiusmod nostrud adipiscing\naliqua enim veniam et aliquip\ncommodo consequat dolore\nesse sed adipiscing cillum\nsed elit et incididunt.",
    top1: 18,
    left1: 3,
    size1: 0.9,
    color1: "#444444",
    border1: 0,
    borderColor1: "#444444",
    alignText1: "left",

    text2: "Mario Rossi",
    top2: 51,
    left2: 48,
    size2: 1.5,
    color2: "#444444",
    border2: 0,
    borderColor2: "#444444",
    alignText2: "left",

    text3: "Via Corso Garibaldi 1",
    top3: 63,
    left3: 48,
    size3: 1.2,
    color3: "#444444",
    border3: 0,
    borderColor3: "#444444",
    alignText3: "left",

    text4: "Milano",
    top4: 74,
    left4: 48,
    size4: 1.2,
    color4: "#444444",
    border4: 0,
    borderColor4: "#444444",
    alignText4: "left",

    textOutside: false,
    imageSelected: null,
  },
  {
    id: 6,
    src: retro6,
    thumb: retro6,
    useText: 4,

    text1:
      "Elit consectetur sit exercitation\nadipiscing sit et sed dolor nostrud\nvoluptate eiusmod ipsum lorem sit\nconsequat do fugiat laboris sed.\nAmet eiusmod nostrud adipiscing\naliqua enim veniam et aliquip\ncommodo consequat dolore\nesse sed adipiscing cillum\nsed elit et incididunt.",
    top1: 18,
    left1: 3,
    size1: 0.9,
    color1: "#444444",
    border1: 0,
    borderColor1: "#444444",
    alignText1: "left",

    text2: "Mario Rossi",
    top2: 51,
    left2: 55,
    size2: 1.5,
    color2: "#444444",
    border2: 0,
    borderColor2: "#444444",
    alignText2: "left",

    text3: "Via Corso Garibaldi 1",
    top3: 60,
    left3: 55,
    size3: 1.2,
    color3: "#444444",
    border3: 0,
    borderColor3: "#444444",
    alignText3: "left",

    text4: "Milano",
    top4: 68,
    left4: 55,
    size4: 1.2,
    color4: "#444444",
    border4: 0,
    borderColor4: "#444444",
    alignText4: "left",

    textOutside: false,
    imageSelected: null,
  },

];
export default presetPostcardRetro;
